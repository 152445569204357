import Keycloak, {
  KeycloakConfig,
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
} from "keycloak-js";
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CredentialInfo, LinkedAccount, UserInfo } from "common/types";
import { apiList, request } from "api";
let keycloak: Keycloak;

const KeycloakAdapterConf: KeycloakConfig = {
  realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM || "",
  url: process.env.NEXT_PUBLIC_KEYCLOAK_URL,
  clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENTID || "",
};

const getKeycloak = () => {
  if (!keycloak) {
    keycloak = new Keycloak(KeycloakAdapterConf);
  }
  return keycloak;
};

type AuthActions = {
  login: (options?: KeycloakLoginOptions) => Promise<void>;
  logout: (options?: KeycloakLogoutOptions) => Promise<void>;
  updateToken: (minValidity: number) => Promise<boolean>;
  updateProfile: (attributes: Record<string, any>) => Promise<boolean>;
  deleteLinkedAccount: (attributes: Record<string, any>) => Promise<boolean>;
};

enum AuthStatus {
  NOT_READY = "NOT_READY",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  AUTHENTICATED = "AUTHENTICATED",
}

type HookProps = {
  keycloak?: Keycloak;
  status: AuthStatus;
  token: string | undefined;
  userInfo: UserInfo;
  isLoading: boolean;
  actions: AuthActions;
};

const AuthContext = createContext<HookProps | undefined>(undefined);

type AuthProviderProps = PropsWithChildren<{}>;

const defaultAuthAction = {
  login: async () => {},
  logout: async () => {},
  updateToken: async () => true,
  updateProfile: async () => true,
  deleteLinkedAccount: async () => true,
};

const defaultUserInfo: UserInfo = {
  username: "",
  email: "",
  email_verified: false,
  given_name: "",
  sub: "",
  name: "",
  family_name: "",
  avatar: "",
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [status, setStatus] = useState<AuthStatus>(AuthStatus.NOT_READY);
  const [token, setToken] = useState<string>();
  const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [actions, setActions] = useState<AuthActions>(defaultAuthAction);

  const kc = useRef<Keycloak>();

  const loadProfile = async () => {
    const keycloak = kc.current;
    if (keycloak) {
      setIsLoadingInfo(true);
      try {
        // get profile
        await keycloak.loadUserInfo();
        const userInfo = keycloak.userInfo as UserInfo;

        // get credentials meta info
        const credentials = await request<CredentialInfo[]>(
          apiList.userAccount.credentials,
          {
            realm: keycloak.realm,
          }
        );
        userInfo.credentials = credentials;

        // get linked accounts
        const linkedAccounts = await request<LinkedAccount[]>(
          apiList.userAccount.linkedAccounts,
          {
            realm: keycloak.realm,
          }
        );
        userInfo.linkedAccounts = linkedAccounts;

        setUserInfo(userInfo);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingInfo(false);
      }
    }
  };

  const updateProfile = useCallback(async (attributes: Record<string, any>) => {
    try {
      console.log("Profile", kc.current?.profile);
      const email =
        kc.current?.profile?.email || (kc.current?.userInfo as UserInfo).email;
      if (!email) {
        return false;
      }

      await request<any>(apiList.userAccount.updateProfile, {
        realm: kc.current?.realm,
        email,
        attributes,
      });
      loadProfile();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }, []);

  const deleteLinkedAccount = useCallback(
    async (attributes: Record<string, any>) => {
      try {
        const providerAlias = attributes.providerAlias;
        if (!providerAlias) {
          return false;
        }
        await request<any>(apiList.userAccount.deleteLinkedAccounts, {
          realm: keycloak.realm,
          providerAlias,
        });
        loadProfile();
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    []
  );

  useEffect(() => {
    if (!kc.current) {
      console.log("creating keycloak instance");
      const keycloak = getKeycloak();

      keycloak.onReady = async (authenticated) => {
        if (keycloak.authenticated) {
          setToken(keycloak.token);
          loadProfile();
        }

        setStatus(
          authenticated ? AuthStatus.AUTHENTICATED : AuthStatus.UNAUTHENTICATED
        );
      };

      keycloak.onAuthError = (errorData) =>
        console.error("auth error", errorData); // TODO toast?

      keycloak.init({
        enableLogging: true,
      });

      kc.current = keycloak;
    }

    setActions({
      login: kc.current.login,
      logout: kc.current.logout,
      updateToken: kc.current.updateToken,
      updateProfile,
      deleteLinkedAccount,
    });
  }, [updateProfile, deleteLinkedAccount]);

  const value = useMemo(
    () => ({
      status,
      userInfo,
      actions,
      token,
      keycloak: kc.current,
      isLoading: isLoadingInfo,
    }),
    [actions, isLoadingInfo, status, token, userInfo]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useKeycloak = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth requires to be used within a AuthProvider");
  }

  return context;
};

export { AuthProvider, AuthStatus, useKeycloak, getKeycloak };
