import { useCurrentOrgId } from "hooks/useOrganizations";
import { Middleware } from "swr";

export const addOrgIdToKey: Middleware =
  (useSWRNext) => (key, fetcher, config) => {
    const organizationId = useCurrentOrgId.getState().current;
    if (organizationId) {
      if (typeof key === "string") {
        return useSWRNext([key, organizationId], fetcher, config);
      } else if (Array.isArray(key)) {
        return useSWRNext(key.concat(organizationId), fetcher, config);
      } else if (typeof key === "object") {
        return useSWRNext({ ...key, organizationId }, fetcher, config);
      }
    }

    return useSWRNext(key, fetcher, config);
  };
