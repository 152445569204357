import { ReactNode } from "react";
import {
  NODE_RANGES,
  SOLC_RANGES,
  PACKAGE_MANAGE_RANGES,
  MODE_RANGES,
  DEFAULT_SCAN_PARAM,
} from "./scanParamsVersion";
import {
  EngineInfo,
  EngineType,
  HistorySortingProps,
  ProjectSortingProps,
  ScanResultSortingProps,
  ScanSetting,
  SortingOption,
  integrationTypeProps,
  IntegraitonCategory,
  IntegrationTypes,
  ScanState,
  CodeCloneSortingProps,
  IssueSeverity,
  FileTypes,
  FrameworkTypes,
  ValidateStatus,
} from "./types";

export const APP_NAME = "MetaTrust";

export const API_ORG_HEADER = "X-MetaScan-Org";

export const API_PATH = "/api";

export const STORAGE_KEY = {
  AUTHORIZATION: "token",
  USER_INFO: "user_info",
};

export const ENGINE_INFOS: EngineInfo[] = [
  {
    name: "GPTScan",
    subTitle:
      "GPTScan is an advanced engine that leverages the power of ChatGPT language model to detect logic vulnerabilities",
    type: EngineType.AI,
    description:
      "GPTScan is designed to identify logic vulnerabilities in smart contracts using advanced program analysis techniques that leverage the power of ChatGPT language models",
    icon: "ai",
    isBeta: true,
  },
  {
    name: "Security Analyzer",
    subTitle:
      "Security Analyzer applies static analysis with comprehensive vulnerability rules to effectively detect security vulnerabilities",
    type: EngineType.STATIC,
    description:
      "Effective security vulnerability detection engine using static analysis with comprehensive vulnerability rules support",
    icon: "analyzer",
  },
  {
    name: "Security Prover",
    subTitle:
      "Security Prover performs formal analysis using our proprietary vulnerability detection and verification algorithms",
    type: EngineType.PROVER,
    description: "Our proprietary symbolic execution and theorem provers.",
    icon: "prover",
    isBeta: true,
  },
  {
    name: "Code Quality",
    subTitle:
      "This engine identifies common issues related to coding style and coding convention compliance",
    type: EngineType.LINT,
    description: " ",
    icon: "lint",
    isBeta: true,
  },
  {
    name: "IP Analyzer",
    subTitle:
      "This engine employs software code clone analysis to identify the IP and security risk associated with third-party code",
    type: EngineType.CODE_CLONE,
    description:
      "IP Analyzer is MetaTrust's proprietary tool to detect vulnerabilities in smart contracts caused by code clones. Vulnerabilities will not only lead to security problems of Web3 software itself, but also cause the spread of vulnerabilities through code clones. It is important to detect and locate vulnerabilities among the source code to facilitate the fix. ",
    icon: "code-clone",
    isBeta: true,
  },
  {
    name: "Open Source Analyzer",
    subTitle:
      "This engine conducts software composition analysis to detect vulnerabilities and license risks within the open-source components used.",
    type: EngineType.SCA,
    description:
      "Detect known security vulnerabilities introduced by open source software",
    icon: "sca",
  },
  // {
  //   name: "MetaTrust Fuzzer",
  //   subTitle: "Dynamic Application Security Testing tool",
  //   type: EngineType.FUZZER,
  //   description: "Dynamic Application Security Testing tool.",
  //   icon: "fuzzer",
  //   isComingSoon: true,
  // },
];

export const DEFAULT_SCAN_SETTING = {
  node: DEFAULT_SCAN_PARAM,
  solc: DEFAULT_SCAN_PARAM,
  package_manage: DEFAULT_SCAN_PARAM,
  mode: DEFAULT_SCAN_PARAM,
  ignored_path: [
    "node_modules",
    "test",
    "tests",
    "mock",
    "@openzeppelin",
    "openzeppelin-contracts",
  ],
};

export const AdvancedSettings: ScanSetting[] = [
  {
    name: "node",
    title: "Node.JS version",
    type: "select",
    ranges: NODE_RANGES,
    description: "The Version of Node.JS",
    descriptionDetails: [
      "Most Ethereum libraries and tools are written in JavaScript. Node.JS is a JavaScript runtime built on Chrome's V8 JavaScript engine and is the most popular solution to run JavaScript outside of a web browser.",
      "Many Web3 frameworks like Hardhat and Truffle are built on top of Node.JS, therefore the security scan engines also need to prepare and compile project's source code with it.",
      "Version 16.15 of Node.JS will be used as default. However, you can choose an alternative version if your project relies on a specific version to compile successfully.",
    ],
  },
  {
    name: "solc",
    title: "Compiler version",
    type: "select",
    ranges: SOLC_RANGES,
    description: "The Version of the Solidity Compiler",
    descriptionDetails: [
      "Solidity is an object-oriented programming language for implementing smart contracts on various blockchain platforms, most notably Ethereum. It is currently the default language supported by security scan engines.",
      "The Solidity version will be detected automatically from your source code and project settings. You can manually specify a Solidity version if your project relies on that version to compile successfully.",
    ],
  },
  {
    name: "package_manage",
    title: "Package manager",
    type: "select",
    ranges: PACKAGE_MANAGE_RANGES,
    description: "The Type of the Package Manager",
    descriptionDetails: [
      "Many Web3 projects are built on a framework and rely on a package manager to install various dependencies.",
      "During the preparation phase of security scanning, the package manager will be detected automatically. However, you can manually specify a package manager if your project relies on it to compile successfully.",
    ],
  },
  {
    name: "compile_command",
    title: "Compiler command",
    placeholder: "Default",
    type: "input",
    description: "The Command for Project Compilation",
    descriptionDetails: [
      "By default, the project's source code will be compiled automatically by detecting the proper command in the project settings.",
      "Please provide any specific compiling command (e.g., npm run build:contract) if the project relies on it to compile successfully.",
    ],
  },
  {
    name: "mode",
    title: "Project type",
    type: "select",
    ranges: MODE_RANGES,
    description: "The Type/Architecture of Project",
    descriptionDetails: [
      "Most Web3 projects are built on top of a framework (e.g., Hardhat, Truffle).",
      "The scan engine will automatically detect the used framework and prepare/install all required dependencies before the compiling and security analysis phase. ",
      'If your project contains only pure solidity files and is not framework-based, you can opt-out of the preparation phase by specifying the project as "file-based".',
    ],
  },
  {
    name: "output_file_info",
    title: "Output file info",
    type: "switch",
    description: "Output File Info",
    descriptionDetails: [
      "Whether to store the directory tree (the structure of files) and SHA checksum for each solidity source code file from the project into the final scan result.",
    ],
  },
  // {
  //   name: "experimental_ai_scan",
  //   title: "Experimental AI scan",
  //   type: "switch",
  //   description: "Experimental AI Scan for Security Analyzer",
  //   descriptionDetails: [
  //     "Whether to activate the experimental AI scan feature for the Security Analyzer engine.",
  //   ],
  // },
];

export const ScanSettings: ScanSetting[] = [
  {
    name: "branch",
    title: "Code Branch",
    placeholder: "Default branch",
    type: "branch",
    description: "The Git Branch for Source Code",
    descriptionDetails: [
      "When the project is added via importing source code from a git repository, the scan engine will fetch and analyze the source code from the default git branch.",
      "If the source code from a specific branch needs to be scanned, please select the corresponding branch.",
    ],
  },
  {
    name: "commit_hash",
    title: "Code Commit",
    placeholder: "Latest commit",
    type: "commit",
    description: "The Git Commit for Source Code",
    descriptionDetails: [
      "When the project is added via importing source code from a git repository, the scan engine will fetch and analyze the code from the latest commit by default.",
      "If the source code from a specific commit needs to be scanned, please select the corresponding commit.",
    ],
  },
  {
    name: "sub_path",
    title: "Workspace Path",
    placeholder: "Auto detect",
    type: "combobox",
    description: "The Base Directory for Security Scan",
    descriptionDetails: [
      "By default, the security scanning will be carried out at the project's root directory.",
      "If the project source code is located inside a sub-directory, please specify it here so the scan engine can accurately locate the code.",
    ],
  },
  {
    name: "entry_contract",
    title: "Entry Contract",
    placeholder: "_MAIN_",
    type: "input",
    description: "The Entry Contract",
    descriptionDetails: [
      "The name of the entry contract used for scanning by Security Prover.",
      "The entry contract should contain the main interface and implementation provided by this project on-chain, as well as interfaces for controlling and managing smart contracts.",
    ],
  },
];

export enum PROJECT_SCAN_STATUS {
  NOTSTART = "NOTSTART",
  PROGRESS = "PROGRESS",
  FINISHED = "FINISHED",
}

export const ROUTE = {
  root: "/",
  signin: "/sign-in",
  integration: "/integration",
  project: "/project",
  repo: "/project/repo",
  addProject: "/project/add",
  redirect: "/oauth/redirect",
  profileSetting: "/settings/profile",
  organization: "/settings/organization",
  billing: "/settings/billing",

  settings: {
    payment: {
      update: "/settings/payment/update",
      confirm: "/settings/payment/confirm",
    },
  },

  subscription: {
    select: "/subscription/select",
    payment: "/subscription/payment",
    confirm: "/subscription/confirmation",
    review: "/subscription/review",
  },
};

export const PROFILE_MENU: Array<{ name: string; href: string }> = [
  {
    name: "Profile",
    href: "/settings/profile",
  },
  {
    name: "Organization",
    href: "/settings/organization",
  },
  {
    name: "Billing & Plans",
    href: "/settings/billing",
  },
];

export const ENGINE_STATE_LABEL = {
  [ScanState.SUCCESS]: "Finished",
  [ScanState.PROGRESS]: "In Progress",
  [ScanState.FAILURE]: "Failed",
  [ScanState.ABORT]: "Aborted",
  [ScanState.PENDING]: "Pending",
  [ScanState.UNKOWN]: "Pending",
  [ScanState.SYSTEM_ABORT]: "Aborted *",
};

export const SCAN_STEP_LABEL: { [prop: string]: string } = {
  prepare: "Preparation",
  project_verify: "Validate project",
  clone: "Retrieve files",
  prepare_install: "Setup environment for project",
  node_install: "Prepare runtime environment",
  solc_install: "Prepare compiler environment",
  dependency_install: "Install project dependencies",
  sca: "Open Source Analyzer",
  sca_install: "Setup environment for Open Source Analyzer",
  sca_scan: "Scan for vulnerabilities",
  parsing: "Prepare environment for security analysis",
  static: "Security Analyzer",
  static_install: "Setup environment for Security Analyzer",
  static_compile: "Compile source code",
  static_scan: "Scan for vulnerabilities",
  static_scan_vulnerability_file_archive: "Collect scan results",
  prover: "Security Prover",
  prover_install: "Setup environment for Security Prover",
  prover_compile: "Prepare compiler environment",
  prover_ast_transform: "Preparing Security Prover scanning",
  prover_scan: "Start Security Prover scanning",
  prover_scan_vulnerability_file_archive:
    "Collect Security Prover scanning results",
  code_clone_scan: "Start IP Analyzer scanning",
  code_clone_vulnerability_file_archive: "Collect IP Analyzer scanning results",
  audit_gpt_scan: "AI assisted scan for vulnerabilities",
  vulnerability_file_archive: "Collect scan result",
};

export enum ScanStatus {
  Pending = "PENDING",
  Scanning = "PROGRESS",
  Finished = "SUCCESS",
  Failed = "FAILURE",
  Aborted = "ABORT",
}

export const SeverityOrders: Record<IssueSeverity, number> = {
  [IssueSeverity.CRITICAL]: 6,
  [IssueSeverity.HIGH]: 5,
  [IssueSeverity.MEDIUM]: 4,
  [IssueSeverity.LOW]: 3,
  [IssueSeverity.WARNING]: 2,
  [IssueSeverity.INFORMATIONAL]: 1,
};

export const SeverityLabels: Record<IssueSeverity, string> = {
  [IssueSeverity.CRITICAL]: "severity.critical",
  [IssueSeverity.HIGH]: "severity.high",
  [IssueSeverity.MEDIUM]: "severity.medium",
  [IssueSeverity.LOW]: "severity.low",
  [IssueSeverity.WARNING]: "severity.warning",
  [IssueSeverity.INFORMATIONAL]: "severity.informational",
};

export enum MemberStatus {
  JOINED = "JOINED",
  PENDING = "PENDING",
}

export const MemberStatusMap = {
  [MemberStatus.JOINED]: "Joined",
  [MemberStatus.PENDING]: "Pending",
};

export enum Role {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}

export const MemberStatusSortValue = {
  [MemberStatus.JOINED]: 1,
  [MemberStatus.PENDING]: 0,
};

export const MemberRoleSortValue = {
  [Role.OWNER]: 2,
  [Role.ADMIN]: 1,
  [Role.MEMBER]: 0,
};

export enum OrganizationType {
  DEFAULT = "DEFAULT",
  CREATED = "CREATED",
}

export const RoleName = {
  [Role.OWNER]: "Owner",
  [Role.ADMIN]: "Admin",
  [Role.MEMBER]: "Member",
};

export enum RoleAuthorities {
  UPDATE_ORG = "update:org",
  TRANSFER_ORG = "transfer:org",
  ADD_MEMBER = "add:member",
  ADD_PROJECT = "add:project",
  VIEW_REPOST = "view:report",
  UPDATE_MEMBER = "update:member",
  REMOVE_ORG = "remove:org",
  VIEW_PROJECT = "view:project",
  VIEW_SCAN = "view:scan",
  REMOVE_MEMBER = "remove:member",
  EXEC_SCAN = "exec:scan",
}

export const DEFAULT_SWR_OPTS = {
  refreshInterval: 0,
  revalidateOnFocus: false,
};

export const DEFAULT_SWR_OPTS_REFRESH = {
  refreshInterval: 60_000,
  revalidateOnFocus: false,
};

export const DEFAULT_SWR_OPTS_FAST = {
  refreshInterval: 15_000,
  revalidateOnFocus: false,
};

export const DEFAULT_SWR_OPTS_FASTER = {
  refreshInterval: 10_000,
  revalidateOnFocus: false,
};

export const DEFAULT_SWR_OPTS_REFRESH_SLOW = {
  refreshInterval: 300_000,
  revalidateOnFocus: false,
};

export const SORTING_OPTS_SCAN_RESULT: SortingOption<ScanResultSortingProps>[] =
  [
    {
      label: "Severity Descending",
      sorter: { property: "severity", ascending: false },
    },
    {
      label: "Severity Ascending",
      sorter: { property: "severity", ascending: true },
    },
  ];

export const SORTING_OPTS_PROJECT: SortingOption<ProjectSortingProps>[] = [
  {
    label: "Creation Time Descending",
    sorter: { property: "createTime", ascending: false },
  },
  {
    label: "Creation Time Ascending",
    sorter: { property: "createTime", ascending: true },
  },
  {
    label: "Scan Time Descending",
    sorter: { property: "lastScanTime", ascending: false },
  },
  {
    label: "Scan Time Ascending",
    sorter: { property: "lastScanTime", ascending: true },
  },
  {
    label: "Project Name (A → Z)",
    sorter: { property: "title", ascending: true },
  },
  {
    label: "Project Name (Z → A)",
    sorter: { property: "title", ascending: false },
  },
  {
    label: "Total Issues Descending",
    sorter: { property: "totalIssues", ascending: false },
  },
  {
    label: "Total Issues Ascending",
    sorter: { property: "totalIssues", ascending: true },
  },
];

export const SORTING_OPTS_HISTORY: SortingOption<HistorySortingProps>[] = [
  {
    label: "Scan Time Descending",
    sorter: { property: "createTime", ascending: false },
  },
  {
    label: "Scan Time Ascending",
    sorter: { property: "createTime", ascending: true },
  },
];

export const SORTING_OPTS_CODE_CLONE: SortingOption<CodeCloneSortingProps>[] = [
  {
    label: "Clone Rate Descending",
    sorter: { property: "rate", ascending: false },
  },
  {
    label: "Clone Rate Ascending",
    sorter: { property: "rate", ascending: true },
  },
];

export const integrationTypeList: integrationTypeProps[] = [
  {
    type: "github",
    baseUrl: "https://github.com",
  },
  {
    type: "gitlab",
    baseUrl: "https://gitlab.com",
  },
];

export const EngineTypeLabels = {
  [EngineType.STATIC]: "Security Analyzer",
  [EngineType.PROVER]: "Security Prover",
  [EngineType.SCA]: "Open Source Analyzer",
  [EngineType.CODE_CLONE]: "IP Analyzer",
  [EngineType.LINT]: "Code Quality",
  [EngineType.AI]: "GPTScan",
};

export const EngineTypeList = [
  EngineType.STATIC,
  EngineType.PROVER,
  EngineType.AI,
  EngineType.LINT,
  EngineType.CODE_CLONE,
  EngineType.SCA,
];

export const ServerityLables = {
  [IssueSeverity.CRITICAL]: "Critical",
  [IssueSeverity.HIGH]: "Hight Risk",
  [IssueSeverity.MEDIUM]: "Medium Risk",
  [IssueSeverity.LOW]: "Low Risk",
  [IssueSeverity.INFORMATIONAL]: "Informational",
};

export const integraitonCategoryLabels = {
  [IntegraitonCategory.CODE_HOSTING]: "Source Code",
  [IntegraitonCategory.AI]: "AI",
  [IntegraitonCategory.IDE]: "IDE",
  [IntegraitonCategory.ISSUE_TRACKING]: "Issue Tracking",
  [IntegraitonCategory.NOTIFICATION]: "Communication",
};

export const ProjectImportType: Record<string, string> = {
  [IntegrationTypes.GITHUB]: "GitHub",
  [IntegrationTypes.PUBLIC_REPO]: "Public Repository",
  [IntegrationTypes.FILE]: "File Upload",
};

export const comparisionData = [
  ["Security Analyzer", "green", "amber", "purple"],
  ["Open-Source Analyzer", "green", "amber", "purple"],
  ["Security Prover", "", "amber", "purple"],
  ["IP Analyzer", "", "amber", "purple"],
  ["Report Generation", "", "amber", "purple"],
  ["POC Generation", "", "amber", "purple"],
  ["CI/CD pipeline integration", "green", "amber", "purple"],
  ["Unlimited Organization Members", "green", "amber", "purple"],
  ["Projects Management", "green", "amber", "purple"],
  ["Organization Management", "green", "amber", "purple"],
  // ["API Integrations", "", "", "purple"],
  // ["On-premise deployment", "", "", "purple"],
  // ["Advanced formal analysis", "", "", "purple"],
  ["15-day Training Guidance", "", "amber", "purple"],
  ["8 × 5 Support Hours", "", "amber", "purple"],
  // ["Dedicated customer success manager", "", "", "purple"],
];
export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  INCOMPLETE = "INCOMPLETE",
  PAST_DUE = "PAST_DUE",
  UNPAID = "UNPAID",
  CANCELED = "CANCELED",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  TRIALING = "TRIALING",
}

export const SubscriptionStatusLables = {
  [SubscriptionStatus.ACTIVE]: "Active",
  [SubscriptionStatus.INCOMPLETE]: "Waiting for payment",
  [SubscriptionStatus.PAST_DUE]: "Past Due",
  [SubscriptionStatus.UNPAID]: "Unpaid",
  [SubscriptionStatus.CANCELED]: "Canceled",
  [SubscriptionStatus.INCOMPLETE_EXPIRED]: "Incomplete expired",
  [SubscriptionStatus.TRIALING]: "Trialing",
};

export const activeSubscriptionStatus = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.TRIALING,
];

export const nonActiveSubscriptionStatus = [
  SubscriptionStatus.CANCELED,
  SubscriptionStatus.INCOMPLETE_EXPIRED,
];

export const pendingSubscriptionStatus = [
  SubscriptionStatus.INCOMPLETE,
  SubscriptionStatus.PAST_DUE,
  SubscriptionStatus.UNPAID,
];

export const PriceInterval = {
  ["year"]: "Annual",
  ["month"]: "Monthly",
};

export const STARTER_SUBS = "starter";
export const PRO_SUBS = "pro";
export const PREMIUM_SUBS = "premium";

export enum OnboardingIds {
  AddProject = "AddProject",
  AddProjectMenu = "AddProjectMenu",
  GithubIntegration = "GithubIntegration",
  GithubRepos = "GithubRepos",
  ProjectFileUpload = "ProjectFileUpload",
  PublicRepo = "PublicRepo",
  ProjectList = "ProjectList",
  ScanForm = "ScanForm",
  ScanDetail = "ScanDetail",
  Welcome = "Welcome",
}

export const AllOnboardingIds = [
  OnboardingIds.AddProject,
  // OnboardingIds.AddProjectMenu,
  OnboardingIds.GithubIntegration,
  OnboardingIds.GithubRepos,
  OnboardingIds.ProjectFileUpload,
  OnboardingIds.PublicRepo,
  OnboardingIds.ProjectList,
  OnboardingIds.ScanForm,
  OnboardingIds.ScanDetail,
];

// export const OnboardingIds: Record<string, string> = {
//   addProject: "addproject",
//   addProjectMenu: "addProjectMenu",
// };

export const FrameworkTypesLabel = {
  [FrameworkTypes.BROWNIE]: "brownie-config.js",
  [FrameworkTypes.BUILDER]: "builder-config.js",
  [FrameworkTypes.EMBARK]: "embark.js",
  [FrameworkTypes.FOUNDRY]: "foundry.toml",
  [FrameworkTypes.HARDHAT]: "hardhat.config.js",
  [FrameworkTypes.TRUFFLE]: "truffle.js",
  [FrameworkTypes.WAFFLE]: "waffle.json",
};

export const frameworkList = [
  FrameworkTypes.BROWNIE,
  FrameworkTypes.BUILDER,
  // FrameworkTypes.EMBARK,
  FrameworkTypes.FOUNDRY,
  FrameworkTypes.HARDHAT,
  FrameworkTypes.TRUFFLE,
  FrameworkTypes.WAFFLE,
];

export const typeList = [
  FileTypes.FRAMEWORK_BASED,
  FileTypes.FRAMEWORK_LESS,
  FileTypes.SINGLE_SOLIDITY,
];

export const validateFileStatusLabel = {
  [ValidateStatus.SUCCESS]: "project.uploadFileStatus.SUCESS",
  [ValidateStatus.ERROR_NON_SOLIDITY_PROJECT]:
    "project.uploadFileStatus.ERROR_NON_SOLIDITY_PROJECT",
  [ValidateStatus.ERROR_NOT_LOOKING_TO_SUPPORT_PLATFORM_PROJECT]:
    "project.uploadFileStatus.ERROR_NOT_LOOKING_TO_SUPPORT_PLATFORM_PROJECT",
  [ValidateStatus.ERROR_FOUNDRY_PROJECT_MISSING_DEPENDENCIES]:
    "project.uploadFileStatus.ERROR_FOUNDRY_PROJECT_MISSING_DEPENDENCIES",
  [ValidateStatus.ERROR_PLATFORM_PROJECT_NOT_PURE_CONTRACT_TYPE_MISMATCH]:
    "project.uploadFileStatus.ERROR_PLATFORM_PROJECT_NOT_PURE_CONTRACT_TYPE_MISMATCH",
  [ValidateStatus.ERROR_UNSUPPORTED_PURE_CONTRACT_DIRECTORY_STRUCTURE]:
    "project.uploadFileStatus.ERROR_UNSUPPORTED_PURE_CONTRACT_DIRECTORY_STRUCTURE",
  [ValidateStatus.WARNING_FOUNDRY_PROJECT_POSSIBLE_MISSING_DEPENDENCIES]:
    "project.uploadFileStatus.WARNING_FOUNDRY_PROJECT_POSSIBLE_MISSING_DEPENDENCIES",
  [ValidateStatus.WARNING_NOT_EXIST_PACKAGE_JSON_POSSIBLE_MISSING_DEPENDENCIES]:
    "project.uploadFileStatus.WARNING_NOT_EXIST_PACKAGE_JSON_POSSIBLE_MISSING_DEPENDENCIES",
  [ValidateStatus.CONFIRM_MULTI_PLATFORM_PROJECT]:
    "project.uploadFileStatus.CONFIRM_MULTI_PLATFORM_PROJECT",
  [ValidateStatus.CONFIRM_MULTI_CONTRACTS_PROJECT]:
    "project.uploadFileStatus.CONFIRM_MULTI_CONTRACTS_PROJECT",
  [ValidateStatus.ERROR_INVALID_SOL]:
    "project.uploadFileStatus.ERROR_INVALID_SOL",
};
export const NetWorks: { chain: string; url: string }[] = [
  {
    chain: "ethereum-mainnet",
    url: "https://etherscan.io",
  },
  {
    chain: "arbitrum-mainnet",
    url: "https://arbiscan.io",
  },
  {
    chain: "avalanche-mainnet",
    url: "https://snowtrace.io",
  },
  {
    chain: "fantom-mainnet",
    url: "https://ftmscan.com",
  },
  {
    chain: "polygon-mainnet",
    url: "https://polygonscan.com",
  },
  {
    chain: "bsc-mainnet",
    url: "https://bscscan.com",
  },
];

export const BYBIT_DISCOUNT_DETAIL = "https://mtl.so/bybit-offer";

export const BYBIT_AD_IMG_LG_URL =
  "https://storage.googleapis.com/metatrust/metascan/bybit-banner-lg.webp";

export const BYBIT_AD_IMG_SM_URL =
  "https://storage.googleapis.com/metatrust/metascan/bybit-banner-sm.webp";
