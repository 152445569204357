export const DEFAULT_SCAN_PARAM = "auto";
export const NODE_RANGES: string[] = [
  DEFAULT_SCAN_PARAM,
  "21.1",
  "21.0",
  "20.9",
  "20.8",
  "20.7",
  "20.6",
  "20.5",
  "20.4",
  "20.3",
  "20.2",
  "20.1",
  "20.0",
  "19.9",
  "19.8",
  "19.7",
  "19.6",
  "19.5",
  "19.4",
  "19.3",
  "19.2",
  "19.1",
  "19.0",
  "18.18",
  "18.17",
  "18.16",
  "18.15",
  "18.14",
  "18.13",
  "18.12",
  "18.11",
  "18.10",
  "18.9",
  "18.8",
  "18.7",
  "18.6",
  "18.5",
  "18.4",
  "18.3",
  "18.2",
  "18.1",
  "18.0",
  "17.9",
  "17.8",
  "17.7",
  "17.6",
  "17.5",
  "17.4",
  "17.3",
  "17.2",
  "17.1",
  "17.0",
  "16.20",
  "16.19",
  "16.18",
  "16.17",
  "16.16",
  "16.15",
  "16.14",
  "16.13",
  "16.12",
  "16.11",
  "16.10",
  "16.9",
  "16.8",
  "16.7",
  "16.6",
  "16.5",
  "16.4",
  "16.3",
  "16.2",
  "16.1",
  "16.0",
  "15.14",
  "15.13",
  "15.12",
  "15.11",
  "15.10",
  "15.9",
  "15.8",
  "15.7",
  "15.6",
  "15.5",
  "15.4",
  "15.3",
  "15.2",
  "15.1",
  "15.0",
  "14",
  "13",
  "12",
  "11",
  "10",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
];

export const SOLC_RANGES: string[] = [
  DEFAULT_SCAN_PARAM,
  // "0.3.6",
  "0.4.0",
  "0.4.1",
  "0.4.2",
  "0.4.3",
  "0.4.4",
  "0.4.5",
  "0.4.6",
  "0.4.7",
  "0.4.8",
  "0.4.9",
  "0.4.10",
  "0.4.11",
  "0.4.12",
  "0.4.13",
  "0.4.14",
  "0.4.15",
  "0.4.16",
  "0.4.17",
  "0.4.18",
  "0.4.19",
  "0.4.20",
  "0.4.21",
  "0.4.22",
  "0.4.23",
  "0.4.24",
  "0.4.25",
  "0.4.26",
  "0.5.0",
  "0.5.1",
  "0.5.2",
  "0.5.3",
  "0.5.4",
  "0.5.5",
  "0.5.6",
  "0.5.7",
  "0.5.8",
  "0.5.9",
  "0.5.10",
  "0.5.11",
  "0.5.12",
  "0.5.13",
  "0.5.14",
  "0.5.15",
  "0.5.16",
  "0.5.17",
  "0.6.0",
  "0.6.1",
  "0.6.2",
  "0.6.3",
  "0.6.4",
  "0.6.5",
  "0.6.6",
  "0.6.7",
  "0.6.8",
  "0.6.9",
  "0.6.10",
  "0.6.11",
  "0.6.12",
  "0.7.0",
  "0.7.1",
  "0.7.2",
  "0.7.3",
  "0.7.4",
  "0.7.5",
  "0.7.6",
  "0.8.0",
  "0.8.1",
  "0.8.2",
  "0.8.3",
  "0.8.4",
  "0.8.5",
  "0.8.6",
  "0.8.7",
  "0.8.8",
  "0.8.9",
  "0.8.10",
  "0.8.11",
  "0.8.12",
  "0.8.13",
  "0.8.14",
  "0.8.15",
  "0.8.16",
  "0.8.17",
  "0.8.18",
  "0.8.19",
];

export const PACKAGE_MANAGE_RANGES: string[] = [
  DEFAULT_SCAN_PARAM,
  "npm",
  "pnpm",
  "yarn",
];

export const MODE_RANGES: string[] = [
  DEFAULT_SCAN_PARAM,
  "sol_project",
  "sol_files",
];
