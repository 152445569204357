import { cx } from "cva";
import { string } from "yup/lib/locale";

export type OverridableClassName<T extends string> =
  | string
  | ((defaultClass: T) => string);

export const calculateClasses = <T extends string>(
  className: OverridableClassName<T> | undefined,
  defaultClass: T
) => {
  if (!className) {
    return defaultClass;
  }

  if (typeof className === "string") {
    return cx(defaultClass, className);
  } else {
    return cx(defaultClass);
  }
};

export type ClassReplacer = string | ((original: string) => string);

export const classx = (originalClass: string, replacer?: ClassReplacer) => {
  if (!replacer) {
    return originalClass;
  }

  if (typeof replacer === "string") {
    return cx(originalClass, replacer);
  } else {
    return replacer(originalClass);
  }
};
