import { ReactElement } from "react";
import { EngineIconType } from "components/icons/EngineIcon";
import {
  MemberStatus,
  OrganizationType,
  Role,
  RoleAuthorities,
  SubscriptionStatus,
} from "./const";

export type Merge<P, T> = Omit<P, keyof T> & T;

export enum ScanStepState {
  PENDING = "PENDING",
  PROGRESS = "PROGRESS",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum EngineType {
  STATIC = "STATIC",
  PROVER = "PROVER",
  SCA = "SCA",
  CODE_CLONE = "CODE_CLONE",
  LINT = "LINT",
  AI = "AI",
}

export enum ScanState {
  UNKOWN = "UNKOWN",
  PENDING = "PENDING",
  PROGRESS = "PROGRESS",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
  ABORT = "ABORT",
  SYSTEM_ABORT = "SYS_ABORT",
}

export enum IssueSeverity {
  CRITICAL = "CRITICAL",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  WARNING = "WARNING",
  INFORMATIONAL = "INFORMATIONAL",
}

export type IssueCode = {
  vunlerabilityTitle: string;
  count: number;
  titleList?: string[];
};

export enum ExportTaskState {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  EXPIRED = "EXPIRED",
}

export type ExportTask = {
  createTime: string;
  id: string;
  parameters: {
    engineTaskId?: string;
    scanTaskId?: string;
  };
  reference: string;
  status: ExportTaskState;
  type: string;
  updateTime: string;
};

export interface EngineInfo {
  icon: EngineIconType;
  name: string;
  subTitle: string;
  type: EngineType;
  description: string;
  isBeta?: boolean;
  isComingSoon?: boolean;
}

export interface ScanSetting {
  name: string;
  title: string;
  type: string;
  placeholder?: string;
  ranges?: string[];
  description?: string;
  descriptionDetails?: string[];
}

export interface ScanParams {
  engine_types: string[];
  node: string;
  solc: string;
  package_manage: string;
  sub_path: string;
  entry_contract?: string;
  output_file_info?: boolean;
  experimental_ai_scan?: boolean | "OFF" | "QUICK" | "SUPER";
  compile_command: string;
  branch: string;
  commit_hash: string;
  mode: string;
  ignored_paths: string[];
  variables: string;
}

export interface ScanParam {
  env: {
    node: string;
    solc: string;
    package_manage: string;
    compile_command: string;
    variables?: string;
  };
  scan: {
    project: string;
    sub_path: string;
    mode: string;
    ignored_paths?: string;
    entry_contract?: string;
    output_file_info?: boolean;
    experimental_ai_scan?: boolean | "OFF" | "QUICK" | "SUPER";
  };
  repo: {
    branch: string;
    commit_hash: string;
  };
  engine_types: EngineType[];
}

export interface SingleEngineResultSummary {
  total: number;
  byImpact: Record<IssueSeverity, number>;
  byQuality: Record<IssueSeverity, number>;
  byType: Record<string, number>;
  extra: Record<string, number>;
}

export interface MultiEngineResultSummary extends SingleEngineResultSummary {
  byEngine: Record<EngineType, number>;
}

export interface ProjectMinimal {
  id: string;
  title: string;
  createTime: string;
  updateTime: string;
}

export interface Project extends ProjectMinimal {
  createdBy: string;
  repoUrl: string;
  integrationType: string;
  scanTask: ScanTask | null;
  engineTasks?: ScanTask[];
  lastScanTime?: string;
  organizationId: string;
}
export interface ScanTask {
  id: string;
  projectId: string;
  organizationId: string;
  createTime: string;
  updateTime: string;
  scanParam: string;
  taskState: ScanState;
  resultSummary: MultiEngineResultSummary;
  engineTasks: EngineTask[];
}

export interface ScanTaskDigest {
  id: string;
  projectId: string;
  organizationId: string;
  createTime: string;
  updateTime: string;
  scanParam: ScanParam;
  taskState: ScanState;
  resultSummary: MultiEngineResultSummary;
}

export type ScanTasksResponse = {
  items: ScanTaskDigest[];
  total: number;
};

export interface EngineTaskResultDigest {
  success: boolean;
  error: string | null;
  impact?: Record<string, number>;
  parameters: {
    node_version: string;
    solc_version: string;
    branch_name: string;
    commit_id: string;
    commit_message?: string;
    loc?: number;
  };
}
export interface EngineTask {
  id: string;
  resultId: string | null;
  logId: string | null;
  createTime: string;
  updateTime: string;
  engineState: ScanState;
  engineType: EngineType;
  resultDigest: EngineTaskResultDigest | null;
  resultSummary: SingleEngineResultSummary;
  resultOverview: Record<string, any> | null; // TODO remove
  resultCode: string | null;
  resultInfo: EngineTaskResultInfo | null;
  taskState?: ScanState;
}

export interface EngineTaskResult {
  engineTaskId: string;
  result: Record<string, any>;
}

export interface EngineTaskLog {
  version: string;
  solc_version: string;
  node_version: string;
  branch_name: string;
  commit_message: string;
  commit_id: string;
  job_id: Record<string, number>;
  loc: number;
  steps: Array<ScanLogStep>;
}

export interface EngineTaskLogData {
  engineTaskId: string;
  log: EngineTaskLog;
}

export interface EngineTaskStateAndLog {
  state: ScanState;
  resultInfo?: EngineTaskResultInfo;
  output?: EngineTaskLog;
}

export interface PagedData<T> {
  items: T[];
  total: number;
  totalPage?: number;
}

export interface ScanEngineDetails {
  title: string;
  scanTime: string;
  scanParam: string; // TODO deserialize raw json as-is on backend then define the actual type here
  result: string; // TODO deserialize raw json as-is on backend then define the actual type here
  engineType: EngineType;
  scanTaskId: string;
}

export interface OwnerProps {
  avatar_url: string;
  id: number;
  login: string;
}
export interface RepoProps {
  clone_url: string;
  created_at: string;
  description?: string;
  full_name: string;
  id: string;
  git_url?: string;
  sshUrl: string;
  fullName?: string;
  namespace: string;
  gitUrl?: string;
  name: string;
  private: boolean;
  updated_at: string;
  url: string;
  // owner: OwnerProps;
  imported: boolean;
}

// export interface GroupedRepos extends OwnerProps {
export interface GroupedRepos {
  namespace: string;
  children: RepoProps[];
}

export type DetailItemProps = {
  elements: [any];
  description: string;
  markdown: string;
  first_markdown_element: string;
  impact: string;
  id: string;
  check: string;
  confidence: string;
};

export type SortType = {
  title: string;
  name: string;
};

export type ScanResultSortingProps = "severity";

export type ProjectSortingProps =
  | "title"
  | "createTime"
  | "lastScanTime"
  | "totalIssues";

export type HistorySortingProps = "createTime";

export type CodeCloneSortingProps = "rate";

export type SortingProps =
  | ScanResultSortingProps
  | HistorySortingProps
  | ProjectSortingProps
  | CodeCloneSortingProps;

export type Sorter<T> = {
  property: T;
  ascending: boolean;
};

export type SortingOption<T> = {
  label: string;
  sorter: Sorter<T>;
};

export type MemberProps = {
  id: number;
  name: string;
  image: string;
  status: MemberStatus;
  email: string;
  role: Role;
};

export type Authoritys = {
  id: string;
  name: RoleAuthorities;
  updateTime: string;
  createTime: string;
};

export type RoleType = {
  authoritys: Authoritys[];
  id: string;
  name: Role;
  updateTime: string;
  createTime: string;
};

export type OrganizationRole = {
  id: string;
  organization: Organization;
  roleType: RoleType;
  updateTime: string;
  createTime: string;
};

export type Organization = {
  id: string;
  name: string;
  description: string | null;
  type: OrganizationType;
  updateTime: string;
  createTime: string;
  settings: Record<string, any>;
};

export type AiDataSharingApproval = {
  mode: "ALL_PROJECT" | "SELECTED_PROJECT";
  selectedProjects: string[];
};

export interface OrganizationMembershipWithSubscription
  extends OrganizationMembership {
  subscription: string | null;
  subscriptionStatus: SubscriptionStatus;
  hasIncomplete: boolean;
}

export type OrganizationMembership = {
  id: string;
  email: string;
  status: MemberStatus;
  organization: Organization;
  role: OrganizationRole;
  confirmationExpiration: string;
  updateTime: string;
  createTime: string;
};

export type UserInfo = {
  email: string;
  email_verified: boolean;
  given_name: string;
  sub: string;
  name: string;
  family_name: string;
  avatar: string;
  username: string;
  nickname?: string;
  credentials?: CredentialInfo[];
  linkedAccounts?: LinkedAccount[];
};

export type CredentialInfo = {
  type: "password" | "otp";
  category: string;
  displayName: string;
  updateAction: string;
  removable: boolean;
  userCredentialMetadatas: Array<{
    credential: { id: string; type: string; createdDate: number };
  }>;
};

export type LinkedAccount = {
  connected: boolean;
  providerAlias: string;
  providerName: string;
  displayName: string;
  social: boolean;
};

export enum ActionStatus {
  SUCCESS = "success",
  CANCEL = "cancelled",
  ERROR = "error",
}
export enum ActionType {
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  DELETE_ACCOUNT = "delete_account",
}
export type Member = {
  id: string;
  createTime: string;
  updateTime: string;
  organization: Organization;
  roleType: RoleType;
};

export type OrganizationWithMembers = {
  organization: Organization;
  members: OrgMembershipWithUserInfo[];
};

export type OrgMembershipWithUserInfo = {
  organizationMembership: OrganizationMembership;
  userInfoVo: KcUserInfo | null;
};

export type KcUserInfo = {
  id: string;
  username: string;
  attributes: {
    avatar: string[];
  };
  email: string;
  emailVerified: boolean;
  createdTimestamp: number;
  enabled: boolean;
};

export type InviteInfo = {
  code: string;
  receiver: string;
  roleId: string;
  organizationName: string;
  organizationId: string;
  inviter: string;
};

export type EngineCountType = {
  issueCountAnalyzer: number;
  issueCountFuzzer: number;
  issueCountProver: number;
  issueCountSca: number;
};

export type IssueCount = {
  critical: number;
  highRisk: number;
  informational: number;
  lowRisk: number;
  mediumRisk: number;
};

export type TrendDataType = {
  engineCount: EngineCountType;
  issueCount: IssueCount;
  statsDate: string;
};

export type ProjectCount = {
  failed: number;
  haveIssues: number;
  total: number;
  unscanned: number;
};

export type OpenIssuesType = {
  critical: number;
  highRisk: number;
  informational: number;
  lowRisk: number;
  mediumRisk: number;
  total: number;
  [key: string]: number;
};

export type TopIssues = {
  name: string;
  count: number;
  percentage: number;
};

export type DashboardData = {
  issueCount: OpenIssuesType;
  projectCount: ProjectCount;
  topIssues: TopIssues[];
};

export type integrationTypeProps = {
  type: string;
  baseUrl: string;
};

export type UploadProject = {
  organizationId: string;
  projectName: string;
  file: File;
};

export type UpdateFileProject = {
  organizationId: string;
  projectId: string;
  file: File;
};

export enum RepoState {
  public = 1,
  private = 2,
  init = 0,
}

export type sampleProject = {
  description: string;
  title: string;
  url: string;
  preferredEngines?: string[];
  name?: string;
  full_name?: string;
  added: boolean;
};

export enum IntegraitonCategory {
  CODE_HOSTING = "CODE_HOSTING",
  AI = "AI",
  IDE = "IDE",
  ISSUE_TRACKING = "ISSUE_TRACKING",
  NOTIFICATION = "NOTIFICATION",
}

export type integraiton = {
  id: string;
  createTime: string;
  updateTime: string;
  type: string;
  attributes?: {
    publicOnly: boolean;
    account: string;
    apiKey: string;
  };
};

export enum IntegrationTypes {
  GITHUB = "github",
  GITLAB = "gitlab",
  BITBUCKET = "bitbucket",
  FILE = "file",
  PUBLIC_REPO = "public_repo",
  OPENAI = "openai",
  VSCODE = "vscode",
  JIRA = "jira",
  SLACK = "slack",
}

export type integraitonTypes = {
  name: IntegrationTypes;
  category: IntegraitonCategory;
  builtIn: boolean;
  available: boolean;
};

export enum AIIntegrationOrigin {
  INTEGRATION = "integration",
  STARTSCAN = "STARTSCAN",
}

export type integrationAllAttributes = {
  name: string;
  category: IntegraitonCategory;
  builtIn: boolean;
  available: boolean;
  key: string;
  icon: ReactElement;
  desc: string;
  data?: {
    id: string;
    createTime: string;
    updateTime: string;
    type: string;
    attributes: {
      publicOnly: boolean;
      account: string;
      apiKey: string;
    };
  };
};

export type EngineTaskResultInfo = {
  code: string | null;
  module?: string | null;
  data: {
    sub_paths?: string[];
  } | null;
  msg:
    | {
        title: string;
        description: string;
      }
    | string
    | null;
};
export type priceProps = {
  currency: string;
  id: string;
  name: string;
  recurringCount: number | string;
  recurringInterval: "month" | "year";
  type: string;
  unitAmount: number | string;
  // 处理数据生成的属性
  totalMonthPrice?: number;
  save?: number;
  linePrice?: number;
};
export enum ProductNames {
  Pro = "MetaScan Pro",
  PREMIUM_1 = "MetaScan Premium Tier 1",
  PREMIUM_2 = "MetaScan Premium Tier 2",
  PREMIUM_3 = "MetaScan Premium Tier 3",
}
export type ProductPrice = {
  active: boolean;
  description: string;
  id: string;
  name: ProductNames;
  projectType: string;
  quota: number;
  price: priceProps;
  prices: Array<priceProps>;
};

export enum ScanUsageStatus {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  LIMIT_REACHED = "LIMIT_REACHED",
}

export type SubscriptionInfo = {
  billingType: string | null;
  createTime: string;
  createdBy: string;
  currentPeriodEnd: string;
  currentPeriodStart: string;
  customerId: string;
  id: string;
  organizationId: string;
  paymentMethodId: string;
  priceId: string;
  priceName: string;
  productId: string;
  subscriptionId: string;
  subscriptionStatus: SubscriptionStatus;
  updateTime: string;
  usageStatus: string | null;
  userEmail: string;
  quota: string;
  trialEnd: string | null;
};

export type SubscriptionPayment = {
  subscriptionId: string;
  setupIntentSecret: string;
  paymentIntentSecret: string;
  skipPayment: boolean;
  paymentMethod: {
    id: string;
    type: string;
    card: {
      brand: string;
      last4: string;
      country: string;
    };
    billingDetails: {
      name: string;
      email: string;
      address: {
        line1: string;
        line2: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
  } | null;
  invoice: {
    amountDue: string;
    currency: string;
    subTotal: string;
    total: string;
    priceId: string;
    discounts: {
      coupon: {
        amountOff: string;
        currency: string;
        duration: string;
        durationInMonths: string | null;
        name: string;
        percentOff: number;
        valid: boolean;
      };
      promotionCode: {
        code: string;
        active: boolean;
      };
    }[];
    lines: {
      amount: string;
      currency: string;
      quantity: string;
      description: string;
      type: string;
      metadata: {
        internalType?: string;
      };
    }[];
  };
};

export type ScanUsage = {
  id: string;
  organizationId: string;
  scanTaskId: string;
  scanExecutedBy: string;
  createTime: number;
  loc: number;
  projectTitle: string;
};

export type ScanUsageSummary = {
  total: string;
  currentPeriodEnd: string;
  currentPeriodStart: string;
};

export type InvoicePaymentMethod = {
  id: string;
  object: string;
  billing_details: {
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    email: string;
    name: string | null;
    phone: string;
  };
  card: {
    brand:
      | "amex"
      | "diners"
      | "discover"
      | "jcb"
      | "mastercard"
      | "unionpay"
      | "visa"
      | "unknown";
    checks: {
      address_line1_check: string | null;
      address_postal_code_check: string | null;
      cvc_check: string;
    };
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    generated_from: string | null;
    last4: string;
    networks: {
      available: string[];
      preferred: string | null;
    };
    three_d_secure_usage: {
      supported: boolean;
    };
    wallet: {
      amex_express_checkout: any;
      apple_pay: any;
      dynamic_last4: any;
      google_pay: any;
      masterpass: any;
      samsung_pay: any;
      type: string;
      visa_checkout: any;
    } | null;
  };
  created: number;
  customer: string;
  livemode: boolean;
  metadata: {
    order_id: string;
  };
  type:
    | "acss_debit" // 暂无
    | "affirm"
    | "afterpay_clearpay" // 暂无
    | "alipay"
    | "au_becs_debit" // 暂无
    | "bacs_debit" // 暂无
    | "bancontact" // 暂无
    | "blik" // 暂无
    | "boleto" // 暂无
    | "card"
    | "card_present"
    | "customer_balance"
    | "eps" // 暂无
    | "fpx" // 暂无
    | "giropay"
    | "grabpay" // 暂无
    | "ideal"
    | "interac_present"
    | "klarna"
    | "konbini" // 暂无
    | "link" // 暂无
    | "oxxo" // 暂无
    | "p24" // 暂无
    | "paynow" // 暂无
    | "pix" // 暂无
    | "promptpay" // 暂无
    | "sepa_debit" // 暂无
    | "sofort" // 暂无
    | "us_bank_account" // 暂无
    | "wechat_pay";
};

export type Invoice = {
  id: string;
  amountDue: string;
  amountPaid: string;
  amountRemaining: string;
  attemptCount: string;
  attempted: true;
  collectionMethod: string;
  created: string;
  currency: string;
  customerEmail: null;
  customerName: null;
  dueDate: null;
  invoicePdf: string;
  number: string;
  paid: boolean;
  status: "draft" | "open" | "paid" | "uncollectible";
  subTotal: string;
  subTotalExcludingTax: string;
  total: string;
  totalExcludingTax: string;
  priceId: string;
  paymentMethod?: InvoicePaymentMethod;
};

export type PreviewInvoice = {
  amountDue: string;
  amountPaid: string;
  amountRemaining: string;
  attemptCount: string;
  attempted: boolean;
  collectionMethod: string;
  created: string;
  currency: string;
  customerEmail: string;
  customerName: string | null;
  dueDate: string | null;
  id: string | null;
  invoicePdf: string | null;
  number: string | null;
  paid: boolean;
  paymentMethod: string | null;
  priceId: string;
  status: string;
  subTotal: string;
  subTotalExcludingTax: string;
  total: string;
  totalExcludingTax: string;
  prorationDate: string;
};

export type StripePaginationData<T> = {
  hasMore: boolean;
  items: Array<T>;
};

export type filterStatsData = {
  byStatus: {
    pending: number;
    progress: number;
    failure: number;
    success: number;
    abort: number;
  };
  bySeverity: {
    critical: number;
    highRisk: number;
    mediumRisk: number;
    lowRisk: number;
    informational: number;
  };
};

export type ScanLogCmd = {
  cmd: string;
  code: number;
  job_id: number;
  output: string;
};

export type ScanLogStep = {
  cmds: ScanLogCmd[];
  duration_millis: number;
  name: string;
  state: ScanStepState;
};

export enum FileTypes {
  SINGLE_SOLIDITY = "Single Solidity File",
  FRAMEWORK_LESS = "Framework-less Solidity Files",
  FRAMEWORK_BASED = "Framework-based Project",
}
export enum FrameworkTypes {
  BROWNIE = "Brownie",
  BUILDER = "Builder",
  EMBARK = "Embark",
  FOUNDRY = "Foundry",
  HARDHAT = "Hardhat",
  TRUFFLE = "Truffle",
  WAFFLE = "Waffle",
}

export interface Entry {
  filename: string;
  directory: boolean;
}

export interface FileNode {
  type: "file";
  name: string;
}

export interface DirectoryNode {
  type: "directory";
  name: string;
  contents: TreeNode[];
}

export type TreeNode = FileNode | DirectoryNode;

export enum ValidateStatus {
  SUCCESS = "ok",
  ERROR_NON_SOLIDITY_PROJECT = "error_non_solidity_project",
  ERROR_NOT_LOOKING_TO_SUPPORT_PLATFORM_PROJECT = "error_not_looking_to_support_platform_project",
  ERROR_FOUNDRY_PROJECT_MISSING_DEPENDENCIES = "error_foundry_project_missing_dependencies",
  ERROR_PLATFORM_PROJECT_NOT_PURE_CONTRACT_TYPE_MISMATCH = "error_platform_project_not_pure_contract_type_mismatch",
  ERROR_UNSUPPORTED_PURE_CONTRACT_DIRECTORY_STRUCTURE = "error_unsupported_pure_contract_directory_structure",
  WARNING_FOUNDRY_PROJECT_POSSIBLE_MISSING_DEPENDENCIES = "warning_foundry_project_possible_missing_dependencies",
  WARNING_NOT_EXIST_PACKAGE_JSON_POSSIBLE_MISSING_DEPENDENCIES = "warning_not_exist_package_json_possible_missing_dependencies",
  CONFIRM_MULTI_PLATFORM_PROJECT = "confirm_multi_platform_project",
  CONFIRM_MULTI_CONTRACTS_PROJECT = "confirm_multi_contracts_project",
  ERROR_INVALID_SOL = "invalid_sol",
}

export type ValidateFileResult = {
  data: {
    workspace: string;
  };
  msg: string;
  status: ValidateStatus | string;
};

export interface SymblicPathContext {
  abnormalTerminationCode: number;
  goHead: boolean;
  pathCondition: string[];
  storage: {
    localVariables:
      | {
          sort: string;
          value: string;
          varName: string;
        }[]
      | null;
    stateVariables:
      | {
          sort: string;
          value: string;
          varName: string;
        }[]
      | null;
  };
}

export interface SymblicPath {
  id: number;
  source: number;
  Statement: {
    line: number;
    sourceCode: string;
  } | null;
  context: SymblicPathContext;
}

export interface SymblicPaths {
  context: any;
  paths: SymblicPath[][];
}

export interface Additionals {
  symblic_paths: SymblicPaths[];
}

export interface SpecVerificationCallstackRuleCallstackPtrWrapperData {
  executedStmt: string;
  sourceName: string;
  sourceLocations: {
    startLine: number;
    startColumn: number;
    endLine: number;
    endColumn: number;
  };
  localVars: { key: string; value: string }[];
  stateVars: { key: string; value: string }[];
  callstack: SpecVerificationCallstackCallstack[];
}

export interface SpecVerificationCallstackRuleCallstackPtrWrapper {
  id: number;
  data: SpecVerificationCallstackRuleCallstackPtrWrapperData;
}

export interface SpecVerificationCallstackCallstack {
  ptr_wrapper: SpecVerificationCallstackRuleCallstackPtrWrapper;
}

export enum SpecVerificationCallstackStatus {
  PASS = "PASS",
  SKIP = "SKIP",
  FAIL = "FAIL",
}

export interface SpecVerificationCallstackRule {
  ruleName: string;
  status: SpecVerificationCallstackStatus;
  callstack: SpecVerificationCallstackCallstack[];
}

export interface SpecVerificationCallstackInvarintItem {
  status: SpecVerificationCallstackStatus;
  execFunction: string;
  callstack: SpecVerificationCallstackCallstack[];
}

export interface SpecVerificationCallstackInvarint {
  invName: string;
  items: SpecVerificationCallstackInvarintItem[];
}

export interface SpecVerificationCallstack {
  rules: SpecVerificationCallstackRule[];
  invarints: SpecVerificationCallstackInvarint[];
}

export interface SpecRuleAndInvarints {
  name: string;
  status?: SpecVerificationCallstackStatus;
  callstack?: SpecVerificationCallstackCallstack[];
  subRows?: SpecRuleAndInvarints[];
}

export interface SpecCallstack {
  name: string;
  localVars: { key: string; value: string }[];
  stateVars: { key: string; value: string }[];
  subRows?: SpecCallstack[];
}

// ---> metastack
export type BuiltWithResultEnhancedSingle = {
  imports: Array<MetaStackLibraryDetail>;
  name: string;
  tags: Array<{ name: string; usage: number }>;
  template: MetastackTemplateDetail[];
  profile?: string;
  methods?: {
    name: string;
    mod: string;
    Optional: boolean;
    sign: string;
    description: string;
  }[];
  events?: {
    name: string;
    sign: string;
    description: string;
  }[];
  sub_contracts: BuiltWithResultSourceCode[];
  source?: BuiltWithResultSourceCodeOrigin;
};
export type BuiltWithResultProfileDetail = {
  profile: string;
  methods: {
    name: string;
    mod: string;
    Optional: boolean;
    sign: string;
    description: string;
  }[];
  events: {
    name: string;
    sign: string;
    description: string;
  }[];
};
export type MetastackTemplateDetail = {
  name: string;
  used: string[];
  not_used: string[];
  hit_ratio: number;
  application_list: string[];
  application_list_map?: Array<BuiltWithResultProjectInfo>;
  description?: string;
  scoreInfo?: Array<{
    id: string;
    scoreId: string;
    logo: string;
    name: string;
  }>;
};
export type MetaStackLibraryDetail = {
  name: string;
  path: string;
  lib_name: string;
  libraryDetail?: BuiltWithResultLibraryDetail;
  application_list: string[];
  application_list_map?: Array<BuiltWithResultProjectInfo>;
};
export type BuiltWithResultLibraryDetail = {
  name: string;
  path: string;
  libName: string;
  description: string;
  count: number;
  gptDescription: string;
  id: number;
  npmUrl: string;
  repoUrl: string;
  scoreInfo?: Array<{
    id: string;
    scoreId: string;
    logo: string;
    name: string;
  }>;
  similarProjects: Array<{ name: string; url: string; Similarity: string }>;
};
export type TreeDataType = {
  type: string;
  name: string;
  children?: TreeDataType[];
};
export type BuiltWithResultSourceCodeOrigin = {
  license: string;
  version: string;
  zipUrl: string;
  structure: TreeDataType[];
};

export type BuiltWithResultSourceCode = {
  // contract: SubContractDetail[];
  name: string;
  source: string;
  type: string;
  loc: { start: string; end: string };
  content?: string | undefined;
  address?: string | null | undefined;
};
export type SubContractDetail = {
  name?: string;
  content?: string;
  address?: string;
};
export type BuiltWithResultProjectInfo = {
  logo: string | null;
  name: string;
  id: string;
  sourceId?: string;
  scoreId?: string;
};

// metastack <---
export type AISuggestionInfoContext = {
  rule: string;
  code?: string;
  lines?: Array<number[]>;
  description?: string;
  organizationId?: string;
  projectId?: string;
  projectName?: string;
  scanTaskId?: string;
};

export type AISuggestionInfo = {
  hash: string;
  type: string;
  context: AISuggestionInfoContext;
  errorMsg?: string;
};

export type AISuggestionSessions = {
  content: string;
  date: string;
  id: string;
  role: string;
  state: boolean;
  actionType?: string;
};
