/* eslint-disable react/jsx-props-no-spreading */
import type { NextPage } from "next";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import { FC, PropsWithChildren } from "react";
import * as Sentry from "@sentry/react";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "@fontsource/roboto-mono/400.css";
import { APP_NAME } from "common/const";
import ProtectedPageLayout from "components/layout/ProtectedPage";
import { AuthProvider } from "hooks/useKeycloak";
import "react-toastify/dist/ReactToastify.css";
import "styles/globals.css";

import ErrorBoundary from "components/ErrorBoundary";
import GoogleAnalytics from "components/layout/GoogleAnalytics";
import Head from "next/head";
import { SWRConfig } from "swr";
import { addOrgIdToKey } from "utils/swrMiddlewares";
import LeadFeeder from "components/layout/LeadFeeder";

export type PageLayout = FC<PropsWithChildren<{}>>;

export type NextPageWithLayout = NextPage & {
  layout?: PageLayout;
  title?: string;
  className?: string;
  showBgImage?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  if ((window as any).gtag) {
    (window as any).gtag("event", name, {
      event_category:
        label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  }
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const title = Component.title ? Component.title : APP_NAME;
  const showBgImage = Component.showBgImage;

  const Layout =
    Component.layout ??
    (({ children }) => (
      <ProtectedPageLayout
        className={Component.className}
        showBgImage={showBgImage}
      >
        {children}
      </ProtectedPageLayout>
    ));

  return (
    <Sentry.ErrorBoundary
      showDialog
      dialogOptions={{
        lang: "en",
      }}
      fallback={({ error, componentStack, resetError }) => (
        <ErrorBoundary
          error={{ ...error, componentStack }}
          resetError={resetError}
        />
      )}
    >
      <AuthProvider>
        <SWRConfig value={{ use: [addOrgIdToKey] }}>
          <Head>
            <title>{title}</title>
          </Head>
          <Layout>
            <Component {...pageProps} />
          </Layout>
          <GoogleAnalytics code={process.env.NEXT_PUBLIC_GA_CODE} />
          <LeadFeeder code={process.env.NEXT_PUBLIC_LEADFEEDER_CODE} />
        </SWRConfig>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
};

export default MyApp;
