export type ApiMethod = "get" | "post" | "put" | "delete" | "patch";

export type ApiConfig = {
  url: string;
  method: ApiMethod;
  isMultipart?: Boolean;
  useBody?: Boolean;
  baseUrl?: string;
};

export const requestDemoUrl = "/gw/stackify-api/v2/contactus";

const user = {
  info: {
    url: "/user",
    method: "get",
  },
};

const integration = {
  fetchIntegration: {
    url: "/integration",
    method: "get",
  },
  fetchIntegrationTypes: {
    url: "/integration/types",
    method: "get",
  },
  delete: {
    url: "/integration/{type}",
    method: "delete",
  },
  fetchRepo: {
    url: "/integration/{client}/repos",
    method: "get",
  },
  check: {
    url: "/integration/{client}",
    method: "get",
  },
  create: {
    url: "/integration/{client}",
    method: "post",
  },
  update: {
    url: "/integration/{client}",
    method: "put",
  },
  refresh: {
    url: "/integration/{client}/repos/refresh",
    method: "get",
  },
};

const project = {
  addProject: {
    url: "/project",
    method: "post",
  },
  fetchAllProjects: {
    url: "/project",
    method: "get",
  },
  fetchProject: {
    url: "/project/{id}",
    method: "get",
  },
  fetchMinimalInfo: {
    url: "/project/minimal",
    method: "get",
  },
  batchAdd: {
    url: "/project/batch-add",
    method: "post",
  },
  getSample: {
    url: "/project/sample-repos?organizationId={organizationId}",
    method: "get",
  },
  addSample: {
    url: "/project/add-samples?organizationId={organizationId}",
    method: "post",
  },
  uploadFile: {
    url: "/project/upload?projectName={projectName}&organizationId={organizationId}",
    method: "post",
    isMultipart: true,
  },
  updateFile: {
    url: "/project/update-file?projectId={projectId}&organizationId={organizationId}",
    method: "post",
    isMultipart: true,
  },
  repoBranch: {
    url: "/project/{projectId}/repo/branch?page={page}&size={pageSize}",
    method: "get",
  },
  repoCommit: {
    url: "/project/{projectId}/repo/commit?branch={branch}&page={page}&size={pageSize}",
    method: "get",
  },
  deleteProject: {
    url: "/project/{projectId}?organizationId={organizationId}",
    method: "delete",
  },
  updateProject: {
    url: "/project/{projectId}?organizationId={organizationId}",
    method: "put",
  },
  checkTitle: {
    url: "/project/{projectId}/check-title?organizationId={organizationId}&title={title}",
    method: "get",
  },
  getFilterStats: {
    url: "/project/stats",
    method: "get",
  },
  checkFile: {
    url: "/project/upload-check?checkType={checkType}",
    method: "post",
  },
};

const scan = {
  startScan: {
    url: "/scan/task?projectId={projectId}&organizationId={organizationId}&action=start-scan",
    method: "post",
  },
  stopScan: {
    url: "/scan/task?projectId={projectId}&organizationId={organizationId}&action=stop-scan",
    method: "post",
  },
  fetchScanState: {
    url: "/scan/state?taskId={taskId}",
    method: "get",
  },
  fetchEngineState: {
    url: "/scan/state?engineTaskId={engineTaskId}",
    method: "get",
  },
  fetchEngineTask: {
    url: "/scan/engineTask/{engineTaskId}",
    method: "get",
  },
  export: {
    url: "scan/report/export",
    method: "get",
  },

  getScanTasks: {
    url: "/scan/task?projectId={projectId}&organizationId={organizationId}&sort=createTime,desc",
    method: "get",
  },

  getScanTask: {
    url: "/scan/task/{scanTaskId}?organizationId={organizationId}",
    method: "get",
  },

  getMultiScanTasks: {
    url: "/scan/tasks?scanTaskIds={scanTaskIds}&organizationId={organizationId}",
    method: "get",
  },

  getEngineTasks: {
    url: "/scan/history/engineList/result?engineTaskIds={engineTaskIds}",
    method: "get",
  },

  getEngineTask: {
    url: "/scan/task/engine/{engineTaskId}?organizationId={organizationId}",
    method: "get",
  },

  getEngineTaskResult: {
    url: "/scan/task/engine/{engineTaskId}/result?organizationId={organizationId}",
    method: "get",
  },

  getEngineTaskLog: {
    url: "/scan/task/engine/{engineTaskId}/log?organizationId={organizationId}",
    method: "get",
  },
  getStackLibrary: {
    url: "/metastack/library",
    method: "get",
  },
  getStackTemplate: {
    url: "/metastack/template",
    method: "get",
  },
};

const summary = {
  organization: {
    url: "/summary/organization/{organizationId}",
    method: "get",
  },
  project: {
    url: "/summary/projectId/{projectId}?organizationId={organizationId}",
    method: "get",
  },
  scanTask: {
    url: "/summary/scanTask/{scanTaskId}?organizationId={organizationId}",
    method: "get",
  },
  engineTask: {
    url: "/summary/engineTask/{engineTaskId}?organizationId={organizationId}",
    method: "get",
  },
};

const exports = {
  getByScanTask: {
    url: "/export/report/{scanTaskId}?organizationId={organizationId}",
    method: "get",
  },
  download: {
    url: "/export/download",
    method: "get",
  },
  exportReport: {
    url: "/export/report/{scanTaskId}",
    method: "post",
    useBody: false,
  },
  exportScanReport: {
    url: "/export/report/scanTask/{scanTaskId}",
    method: "post",
    useBody: false,
  },
};

const scanHistory = {
  fetchAllHistory: {
    url: "/scan/history",
    method: "get",
  },
  fetchOneHistory: {
    url: "/scan/history/{peojectId}",
    method: "get",
  },
  fetchAllHistoryByEngineType: {
    url: "/scan/{engineType}/history",
    method: "get",
  },
  fetchEngineHistoryList: {
    url: "/scan/history/engine-task",
    method: "get",
  },
  fetchScanInfo: {
    url: "/scan/history/engine/{engineTaskId}/info",
    method: "get",
  },
  fetchScanDetail: {
    url: "/scan/history/engine/{engineTaskId}/result",
    method: "get",
  },
  fetchSourceFile: {
    url: "/scan/history/vulnerability-files/{filePath}",
    method: "get",
  },
};

const organization = {
  fetchList: {
    url: "/user/orgs",
    method: "get",
  },
  fetchOrg: {
    url: "/organization/{id}",
    method: "get",
  },
  checkOrgName: {
    url: "/organization/checkName",
    method: "get",
  },
  create: {
    url: "/organization",
    method: "post",
  },
  update: {
    url: "/organization",
    method: "put",
  },
  invite: {
    url: "/organization/invitation-member",
    method: "post",
  },
  fetchRoles: {
    url: "/organization/{id}/roles",
    method: "get",
  },
  exit: {
    url: "/organization/exit",
    method: "put",
  },
  dissolution: {
    url: "/organization/dissolution",
    method: "put",
  },
  transferOwnership: {
    url: "/organization/transfer",
    method: "put",
  },
  changeRole: {
    url: "/organization/user/role",
    method: "put",
  },
  removeMember: {
    url: "/organization/member",
    method: "delete",
  },
  join: {
    url: "/organization/join-organization",
    method: "post",
  },
  inviteInfo: {
    url: "/organization/invitation-info",
    method: "get",
  },
  cancel: {
    url: "/organization/cancel-invitation",
    method: "post",
  },
};

const KC_URL = process.env.NEXT_PUBLIC_KEYCLOAK_URL;

const userAccount = {
  approveDeletion: {
    url: "/organization/checkOwnedOrgs",
    method: "get",
  },
  updateProfile: {
    baseUrl: KC_URL,
    url: "/realms/{realm}/account",
    method: "post",
  },
  credentials: {
    baseUrl: KC_URL,
    url: "/realms/{realm}/account/credentials",
    method: "get",
  },
  linkedAccounts: {
    baseUrl: KC_URL,
    url: "/realms/{realm}/account/linked-accounts",
    method: "get",
  },
  deleteLinkedAccounts: {
    baseUrl: KC_URL,
    url: "/realms/{realm}/account/linked-accounts/{providerAlias}",
    method: "delete",
  },
  devices: {
    baseUrl: KC_URL,
    url: "/realms/{realm}/account/sessions/devices",
    method: "get",
  },
};

const dashboard = {
  fetch: {
    url: "/stats/current/{organizationId}",
    method: "get",
  },
  fetchTrendData: {
    url: "/stats/aggregation/{orgId}",
    method: "get",
  },
};

const billing = {
  fetchProducts: {
    url: "/billing/product-prices",
    method: "get",
  },
  fetchAllSubInfo: {
    url: "/billing/subscription/all",
    method: "get",
  },
  fetchSubInfo: {
    url: "/billing/subscription",
    method: "get",
  },
  fetchPaymentInfo: {
    url: "/billing/subscription/payment",
    method: "get",
  },
  createSubscription: {
    url: "/billing/subscription",
    method: "post",
    useBody: false,
  },
  promotion: {
    url: "/billing/subscription/promotion",
    method: "post",
    useBody: false,
  },
  verify: {
    url: "/billing/subscription/payment/verify",
    method: "post",
    useBody: false,
  },
  changeSubscription: {
    url: "/billing/subscription",
    method: "put",
    useBody: false,
  },
  cancelSubscription: {
    url: "/billing/subscription/cancel",
    method: "post",
  },
  fetchUsage: {
    url: "/billing/usage",
    method: "get",
  },
  fetchUsageSummary: {
    url: "/billing/usage/summary",
    method: "get",
  },
  fetchInvoice: {
    url: "/billing/invoice",
    method: "get",
  },
  invoicePreview: {
    url: "/billing/invoice/preview",
    method: "get",
  },
  createCardPayment: {
    url: "/billing/subscription/payment/card",
    method: "post",
  },
  updatePayment: {
    url: "/billing/subscription/payment/update",
    method: "get",
  },
  defaultPaymentInfo: {
    url: "/billing/subscription/payment/info",
    method: "get",
  },
};

const ai = {
  fetchSuggestions: {
    url: "/ai/issue-suggestion?regenerate={regenerate}",
    method: "post",
  },
  thumbs: {
    url: "/ai/issue-suggestion/{id}",
    method: "put",
    useBody: false,
  },
  chat: {
    url: "/llm/chat",
    method: "post",
  },
  getSessions: {
    url: "/llm/sessions",
    method: "post",
  },
  querySessionsByGroupId: {
    url: "/llm/sessions",
    method: "get",
  },
  stop: {
    url: "/llm/chat/stop",
    method: "post",
  },
  getPrompts: {
    url: "/llm/prompts",
    method: "get",
  },
};

const info = {
  settings: {
    url: "/info/settings",
    method: "get",
  },
};

export const openUrl = (path: string, target: string = "_blank") => {
  window.open(path, target);
};

export type UserApiSection = Record<keyof typeof user, ApiConfig>;
export type ProjectApiSection = Record<keyof typeof project, ApiConfig>;
export type ScanApiSection = Record<keyof typeof scan, ApiConfig>;
export type ExportApiSection = Record<keyof typeof exports, ApiConfig>;
export type SummaryApiSection = Record<keyof typeof summary, ApiConfig>;
export type IntegrationApiSection = Record<keyof typeof integration, ApiConfig>;
export type scanHistoryApiSection = Record<keyof typeof scanHistory, ApiConfig>;
export type organizationApiSection = Record<
  keyof typeof organization,
  ApiConfig
>;
export type userAccountApiSection = Record<keyof typeof userAccount, ApiConfig>;
export type DashboardApiSection = Record<keyof typeof dashboard, ApiConfig>;
export type BillingApiSection = Record<keyof typeof billing, ApiConfig>;
export type AiApiSection = Record<keyof typeof ai, ApiConfig>;
export type InfoApiSection = Record<keyof typeof info, ApiConfig>;

const apiList = {
  user: user as UserApiSection,
  project: project as ProjectApiSection,
  scan: scan as ScanApiSection,
  summary: summary as SummaryApiSection,
  exports: exports as ExportApiSection,
  integration: integration as IntegrationApiSection,
  scanHistory: scanHistory as scanHistoryApiSection,
  organization: organization as organizationApiSection,
  userAccount: userAccount as userAccountApiSection,
  dashboard: dashboard as DashboardApiSection,
  billing: billing as BillingApiSection,
  ai: ai as AiApiSection,
  info: info as InfoApiSection,
};

export default apiList;
