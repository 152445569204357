import axios from "axios";
import { API_ORG_HEADER, API_PATH } from "common/const";
import { UserInfo } from "common/types";
import { getKeycloak } from "hooks/useKeycloak";
import { useCurrentOrgId } from "hooks/useOrganizations";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL: API_PATH,
  timeout: 90000,
});

const API_WHITELIST = ["/user/orgs"];

instance.interceptors.request.use(
  async (config) => {
    const keycloak = getKeycloak();

    if (keycloak && keycloak.authenticated && keycloak.token) {
      if (keycloak.isTokenExpired(10)) {
        const tokenOk = await keycloak.updateToken(10);
        if (tokenOk) {
          config.headers!["Authorization"] = `Bearer ${keycloak.token}`;
        } else {
          console.error("Token refresh failed");
        }
      } else {
        config.headers!["Authorization"] = `Bearer ${keycloak.token}`;
      }

      if (
        config.baseURL === API_PATH &&
        !API_WHITELIST.includes(config.url ?? "")
      ) {
        const { current: orgId, currentUser: email } =
          useCurrentOrgId.getState();

        if (
          !!email &&
          email === (keycloak.userInfo as UserInfo)?.email &&
          orgId
        ) {
          config.headers![API_ORG_HEADER] = orgId;
        } else {
          console.debug(
            `Request to ${config.url} aborted due to missing org-id header`
          );
          const controller = new AbortController();
          config.signal = controller.signal;
          controller.abort();
        }
      }
    }

    return config;
  },
  (err) => Promise.reject(err)
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.response?.status === 401) {
      console.warn("401 unauthorized returned from server");
      const keycloak = getKeycloak();
      if (typeof window !== "undefined" && keycloak) {
        // keycloak.login();
        console.error("Server returns 401 while makeing request");
        window.location.href = "/error?error=401";
      }
    }

    if (err?.response?.status === 403) {
      const siteUrl =
        process.env.NEXT_PUBLIC_SITE_HOME || "https://alpha-app.metatrust.io";
      switch (err.response.data?.code) {
        case 403003:
          break;
        case 403004:
          break;
        case 403002:
          toast.error(
            "You do not have the necessary privileges to perform this action.",
            {
              toastId: "error-403002",
            }
          );
          // if (typeof location !== "undefined") {
          //   location.href = `${siteUrl}/project`;
          // }
          break;
        default:
          toast.error("Operation failed due to lack of authorities");
      }
    }

    return Promise.reject(err.response);
  }
);

export default instance;
