import axiosStatic, { AxiosRequestConfig } from "axios";
import apiList, { ApiConfig } from "./apiList";
import axios from "./axios";

export type ApiResponse<T> = {
  data: T;
  code: number;
  message: string;
};

const pathVariablePattern = /{([^}]+)}/g;

const request = async <T>(
  apiConfig: ApiConfig,
  data: Record<string, any> = {},
  params: Record<string, any> = {},
  headers: Record<string, any> = {}
) => {
  const url = apiConfig.url.replace(pathVariablePattern, (_, g1) => {
    const value = data[g1] || "";
    delete data[g1];
    return encodeURIComponent(value);
  });
  const requestConfig: AxiosRequestConfig = {
    method: apiConfig.method,
    url,
    headers,
  };

  if (apiConfig.isMultipart) {
    requestConfig.headers = {
      ...requestConfig.headers,
      "Content-Type": "multipart/form-data",
    };
    requestConfig.data = data;
    requestConfig.params = params;
  } else if (apiConfig.useBody === false || apiConfig.method === "get") {
    requestConfig.params = { ...data, ...params };
  } else {
    requestConfig.data = data;
    requestConfig.params = params;
  }
  if (apiConfig.baseUrl) {
    requestConfig.baseURL = apiConfig.baseUrl;
  }
  let retried = false;

  const doRequest = async () => {
    const rsp = await axios.request<T>(requestConfig);
    return rsp.data;
  };

  try {
    return await doRequest();
  } catch (err) {
    if (
      axiosStatic.isAxiosError(err) &&
      err.response?.status === 403 &&
      !retried
    ) {
      retried = true;
      // await store.dispatch(signIn());
      // return await doRequest();
      console.error(err);
    }
    throw err;
  }
};

const api = async <T>(
  apiConfig: ApiConfig,
  data: Record<string, any> = {},
  params?: Record<string, any>,
  headers?: Record<string, any>
) => await request<ApiResponse<T>>(apiConfig, data, params, headers);

export { apiList, request };

export default api;
