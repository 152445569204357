import { ROUTE } from "common/const";
import Loading from "components/icons/Loading";
import { AuthStatus, useKeycloak } from "hooks/useKeycloak";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { FC, PropsWithChildren } from "react";
import { PublicPageProps } from "./PublicPage";

const PublicPageLayout = dynamic(() => import("./PublicPage"), {
  ssr: false,
});

type ProtectedPageProps = {
  unauthorizedRoute?: string;
  showBgImage?: boolean;
} & Omit<PublicPageProps, "showBgImage">;

const PageLoading = () => (
  <div className="flex h-64 flex-col items-center justify-center">
    <Loading className="h-12 w-12 text-orange-400" />
  </div>
);

const ProtectedPageLayout: FC<PropsWithChildren<ProtectedPageProps>> = ({
  children,
  unauthorizedRoute = ROUTE.signin,
  showBgImage = false,
  ...props
}) => {
  const { status, actions } = useKeycloak();

  if (status === AuthStatus.NOT_READY) {
    return <PageLoading />;
  } else if (status === AuthStatus.UNAUTHENTICATED) {
    actions.login();
    return <PageLoading />;
  } else {
    return (
      <PublicPageLayout showBgImage={showBgImage} {...props}>
        {children}
      </PublicPageLayout>
    );
  }
};

export default ProtectedPageLayout;
