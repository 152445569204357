import Script from "next/script";

export default function GoogleAnalytics({ code }: { code?: string }) {
  if (!code) {
    return <></>;
  } else {
    return (
      <>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${code}`}
        />
        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${code}');
            `,
          }}
        ></Script>
      </>
    );
  }
}
