import api, { apiList } from "api";
import {
  Organization,
  OrganizationMembership,
  OrganizationMembershipWithSubscription,
  OrganizationWithMembers,
} from "common/types";
import useSWR from "swr";
import { AuthStatus, useKeycloak } from "./useKeycloak";
import create from "zustand";
import { persist } from "zustand/middleware";
import {
  MemberRoleSortValue,
  MemberStatusSortValue,
  OrganizationType,
} from "common/const";
import { toast } from "react-toastify";

export function useMemberships() {
  const { status } = useKeycloak();

  const isAuthenticated = status === AuthStatus.AUTHENTICATED;

  const { data, error, mutate } = useSWR(
    [apiList.organization.fetchList.url, isAuthenticated],
    async (url) => {
      if (isAuthenticated) {
        const { data } = await api<OrganizationMembershipWithSubscription[]>(
          apiList.organization.fetchList
        );
        return data;
      } else {
        return [] as OrganizationMembershipWithSubscription[];
      }
    },
    {
      refreshInterval: (data?: OrganizationMembershipWithSubscription[]) => {
        if (data) {
          return data?.length ? 60_000 : 2000;
        } else {
          return 2000;
        }
      },
      revalidateOnFocus: false,
    }
  );

  return {
    memberships: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

interface OrgIdState {
  current: string | null;
  currentOrgName: string | null;
  currentUser: string | null;
  setCurrent: (org: Organization | null, user: string) => void;
}

export const useCurrentOrgId = create<OrgIdState>()(
  persist(
    (set) => ({
      current: null,
      currentOrgName: null,
      currentUser: null,
      setCurrent: (org, user) =>
        set((state) => ({
          ...state,
          current: org?.id,
          currentOrgName: org?.name,
          currentUser: user,
        })),
    }),
    {
      name: "currentOrgId",
      getStorage: () => localStorage,
    }
  )
);

export const useOrganization = (id: string) => {
  const { data, error, mutate } = useSWR(
    [apiList.organization.fetchOrg.url, id],
    async (url) => {
      if (id) {
        const { data } = await api<OrganizationWithMembers>(
          apiList.organization.fetchOrg,
          { id }
        );
        return data;
      } else {
        return undefined;
      }
    },
    {
      refreshInterval: (data: any) => {
        if (data) {
          return data.organization ? 60_000 : 2000;
        } else {
          return 2000;
        }
      },
      revalidateOnFocus: false,
    }
  );

  return {
    organization: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export const useCurrentOrg = () => {
  let membership: OrganizationMembership | undefined;

  const {
    current: currentOrgId,
    setCurrent,
    currentOrgName,
    currentUser,
  } = useCurrentOrgId(); // look for the current org id;
  const {
    memberships,
    isLoading: membershipsLoading,
    isError: membershipsError,
    mutate: mutateMemberships,
  } = useMemberships();
  const { userInfo, isLoading: isKcLoading } = useKeycloak();

  if (memberships && memberships.length && !isKcLoading) {
    // check if the current org still exists
    if (currentOrgId) {
      membership = memberships.find((m) => m.organization.id === currentOrgId);
    }

    // if no currnt id or current org does not exist, fallback to default org
    if (!membership) {
      if (userInfo.email && currentUser && userInfo.email === currentUser) {
        toast.info(
          "You are no long the member of organization " + currentOrgName
        );
      }

      membership = memberships.find(
        (m) => m.organization.type === OrganizationType.DEFAULT
      );
      setCurrent(membership?.organization || null, userInfo.email);
    } else if (!currentUser || userInfo.email !== currentUser) {
      setCurrent(membership.organization || null, userInfo.email);
    }
  }

  // fetch details of default org
  const { organization, isLoading, isError, mutate } = useOrganization(
    membership?.organization.id || ""
  );

  return {
    organization: organization?.organization,
    membership,
    members: organization?.members
      .sort((a, b) =>
        a.organizationMembership.createTime <
        b.organizationMembership.createTime
          ? -1
          : 1
      )
      .sort(
        (a, b) =>
          MemberStatusSortValue[b.organizationMembership.status] -
          MemberStatusSortValue[a.organizationMembership.status]
      )
      .sort(
        (a, b) =>
          MemberRoleSortValue[b.organizationMembership.role.roleType.name] -
          MemberRoleSortValue[a.organizationMembership.role.roleType.name]
      ),
    setCurrent,
    mutate: () => {
      mutate();
      mutateMemberships();
    },
    isLoading: isLoading || membershipsLoading,
    isError: isError || membershipsError,
  };
};

export const useOrgId = () => {
  return useCurrentOrgId((state) => ({
    orgId: state.current,
    orgName: state.currentOrgName,
    user: state.currentUser,
  }));
};
