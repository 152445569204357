import { cva, VariantProps } from "cva";
import Link from "next/link";
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
} from "react";
import { classx, ClassReplacer } from "utils/style";
import LoadingCircular from "./LoadingCircular";

// const BUTTON_BASE_CLASS =
//   "group shadow-sm flex items-center justify-center active:shadow-none";

const BUTTON_BASE_CLASS =
  "group flex items-center justify-center active:shadow-none";

const DEFAULT_DISABLED_CLASS =
  "disabled:border-gray-300 disabled:text-gray-400 disabled:bg-gray-200 disabled:shadow-none";

const buttonStyle = cva(BUTTON_BASE_CLASS, {
  variants: {
    intent: {
      primary: [
        "text-orange-500 hover:text-orange-600",
        "border border-orange-500 hover:border-orange-600 outline-orange-500",
        "bg-white hover:bg-orange-50 active:bg-orange-100",
        DEFAULT_DISABLED_CLASS,
      ],
      secondary: [
        "text-gray-500 hover:text-gray-600",
        "border border-gray-300 hover:border-gray-400 outline-gray-400",
        "bg-white hover:bg-gray-50 active:bg-gray-100",
        DEFAULT_DISABLED_CLASS,
      ],
      danger: [
        "text-white hover:text-red-50",
        "border border-red-500 hover:border-red-600 outline-red-200",
        "bg-red-500 hover:bg-red-600 active:bg-red-700",
        DEFAULT_DISABLED_CLASS,
      ],
      highlight: [
        "text-white hover:text-white",
        "border border-orange-400 outline-orange-300",
        "bg-gradient-to-br from-orange-400 to-red-500 hover:from-orange-500 hover:to-red-600 active:from-orange-600 active:to-red-600",
        "disabled:border-gray-300 disabled:text-gray-400 disabled:from-gray-200 disabled:to-gray-200 disabled:shadow-none",
      ],
    },
    size: {
      xs: ["text-xs", "gap-1 py-1"],
      sm: ["text-sm", "gap-1.5 py-1.5"],
      base: ["text-base", "gap-2 py-2"],
      lg: ["text-lg", "gap-3 py-3"],
      xl: ["text-xl", "gap-4 py-3"],
    },
    pill: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    { size: "xs", pill: true, class: "rounded-full px-4" },
    { size: "xs", pill: false, class: "rounded px-3" },
    { size: "sm", pill: true, class: "rounded-full px-8" },
    { size: "sm", pill: false, class: "rounded-md px-5" },
    { size: "base", pill: true, class: "rounded-full px-10" },
    { size: "base", pill: false, class: "rounded-lg px-5" },
    { size: "lg", pill: true, class: "rounded-full px-12" },
    { size: "lg", pill: false, class: "rounded-lg px-8" },
    { size: "xl", pill: true, class: "rounded-full px-12" },
    { size: "xl", pill: false, class: "rounded-lg px-10" },
  ],
  defaultVariants: {
    intent: "primary",
    size: "base",
  },
});

const iconStyle = cva("flex items-center justify-center", {
  variants: {
    intent: {
      primary: "text-orange-500 group-hover:text-orange-600",
      secondary: "text-gray-500 group-hover:text-gray-600",
      danger: "text-white group-hover:text-red-50",
      highlight: "text-white group-hover:text-white",
    },
    size: {
      xs: "w-4 h-4",
      sm: "w-5 h-5",
      base: "w-6 h-6",
      lg: "w-7 h-7",
      xl: "w-8 h-8",
    },
    hasLeading: {
      true: "",
    },
    hasTrailing: {
      true: "",
    },
  },
  compoundVariants: [
    { size: "xs", hasLeading: true, class: "-ml-1" },
    { size: "xs", hasTrailing: true, class: "-mr-1" },
    { size: "sm", hasLeading: true, class: "-ml-1" },
    { size: "sm", hasTrailing: true, class: "-mr-1" },
    { size: "base", hasLeading: true, class: "-ml-1.5" },
    { size: "base", hasTrailing: true, class: "-mr-1.5" },
    { size: "lg", hasLeading: true, class: "-ml-2" },
    { size: "lg", hasTrailing: true, class: "-mr-2" },
    { size: "xl", hasLeading: true, class: "-ml-3" },
    { size: "xl", hasTrailing: true, class: "-mr-3" },
  ],
  defaultVariants: {
    intent: "primary",
    size: "base",
  },
});

export interface ButtonProps
  extends VariantProps<typeof buttonStyle>,
    Omit<ComponentPropsWithoutRef<"button">, "className"> {
  isLoading?: boolean;
  loadingIcon?: ReactElement;
  leadingIcon?: ReactElement;
  trailingIcon?: ReactElement;
  href?: string;
  className?: ClassReplacer;
}

const withFullSize = (icon: ReactElement) =>
  React.cloneElement(icon, {
    className: (icon.props.className || "") + " w-full h-full",
  });

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function buttonWithRef(
    {
      isLoading = false,
      className = "",
      intent = "primary",
      pill = false,
      size = "base",
      href = "",
      loadingIcon = <LoadingCircular className="h-5/6 w-5/6" />,
      leadingIcon,
      trailingIcon,
      children,
      ...props
    },
    ref
  ) {
    const iconClass = iconStyle({
      intent,
      size,
      hasLeading: !!leadingIcon,
      hasTrailing: !!trailingIcon,
    });

    const btnClass = classx(buttonStyle({ intent, pill, size }), className);

    const iconLeftActual = (
      <>
        {leadingIcon && (
          <div className={iconClass}>
            {isLoading ? loadingIcon : withFullSize(leadingIcon)}
          </div>
        )}
        {isLoading && !leadingIcon && !trailingIcon && (
          <div className={iconClass}>{loadingIcon}</div>
        )}
      </>
    );

    const iconRightActual = (
      <>
        {trailingIcon && (
          <div className={iconClass}>
            {isLoading ? loadingIcon : withFullSize(trailingIcon)}
          </div>
        )}
      </>
    );

    if (href) {
      return (
        <Link href={href}>
          <a className={`${btnClass} max-w-fit cursor-pointer`}>
            {iconLeftActual}
            {children}
            {iconRightActual}
          </a>
        </Link>
      );
    } else {
      return (
        <button className={btnClass} ref={ref} {...props}>
          {iconLeftActual}
          {children}
          {iconRightActual}
        </button>
      );
    }
  }
);

export default Button;
